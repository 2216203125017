import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Element } from "react-scroll";
import "./global.css";
import ReactGA from "react-ga4";
import Header from "./components/sections/header";
import Footer from "./components/sections/footer";
import Metrics from "./components/sections/metrics";
import GraphicPreparation from "./components/sections/graphic_preparation";
import FAQ from "./components/sections/faq";
import Pricing from "./components/sections/pricing";
import Gallery from "./components/sections/gallery";
import Features from "./components/sections/features";
import Hero from "./components/sections/hero";
import Services from "./components/sections/services";
import Testimonials from "./components/sections/testimonials";
import TermsOfService from "./components/pages/terms_of_service";
import PrivacyPolicy from "./components/pages/privacy_policy";
import CookiesPolicy from "./components/pages/cookies";
import Contact from "./components/pages/contact";
import PaymentPolicy from "./components/pages/payment_policy";
import OrderConfirmation from "./components/pages/order_confirmation";
import DeliveryPolicy from "./components/pages/delivery_policy";
import GraphicPreparationSite from "./components/pages/graphic_preparation";
import Order from "./components/pages/order";
import NotFound from "./components/pages/not_found";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./components/ui/alert-dialog";

import cookie from "./assets/svg/cookie.svg";
import { Switch } from "./components/ui/switch";
import { TranslationProvider } from "./TranslationContext";
import { useTranslation } from "./TranslationContext";
import sk from "./locales/sk.json";
import en from "./locales/en.json";
import cz from "./locales/cz.json";
import de from "./locales/de.json";

const languageMap: { [key: string]: typeof en } = {
  "dtf.nexprim.com": en,
  "dtfprint.cz": cz,
  "dtfprint.at": de,
  default: sk,
};

const getLanguage = (hostname: string): typeof en => {
  return languageMap[hostname] || languageMap["default"];
};

// Define a type for the userPreferences state
type UserPreferencesType = {
  necessary: boolean;
  preferences: boolean;
  analytical: boolean;
  marketing: boolean;
};

declare global {
  interface Window {
    init_ga: () => void;
    init_gtm: () => void;
    init_hotjar: () => void;
  }
}

function InnerApp() {
  const [translations, setTranslations] = useState(sk);

  useEffect(() => {
    const hostname = window.location.hostname;
    const selectedLanguage = getLanguage(hostname);
    setTranslations(selectedLanguage);
  }, []);

  const location = useLocation();

  //Toto tu musi byť inak nebude sticky navbar
  const [isSelectOpen] = useState(false);

  // Add these states for controlling the AlertDialogs
  const [isFirstDialogOpen, setFirstDialogOpen] = useState(false);
  const [isSecondDialogOpen, setSecondDialogOpen] = useState(false);

  // Initialize userPreferences state with default values or from cookies
  const [userPreferences, setUserPreferences] = useState<UserPreferencesType>(
    () => {
      // Try to get the cookie
      const cookieValue = Cookies.get("cookies-preference");
      if (cookieValue) {
        // If the cookie exists, parse its value and return it to initialize the state
        return JSON.parse(cookieValue);
      }
      // Default values if the cookie doesn't exist or if something blocked the element
      return {
        necessary: true,
        preferences: false,
        analytical: false,
        marketing: false,
      };
    }
  );

  useEffect(() => {
    // Check if the cookies-preference cookie is set
    const cookiePreferences = Cookies.get("cookies-preference");
    if (!cookiePreferences) {
      setFirstDialogOpen(true);
    }
  }, []);

  // Explicitly type the parameters for updateUserPreferences
  const updateUserPreferences = (
    type: keyof UserPreferencesType,
    value: boolean
  ) => {
    setUserPreferences((prevPreferences) => ({
      ...prevPreferences,
      [type]: value,
    }));
  };

  // Explicitly type the parameters for setCookie
  const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
  };

  const handleAcceptAll = () => {
    // Update userPreferences to enable all types of cookies
    setUserPreferences({
      necessary: true,
      preferences: true,
      analytical: true,
      marketing: true,
    });
    // Set cookie for 1 year
    setCookie(
      "cookies-preference",
      JSON.stringify({
        necessary: true,
        preferences: true,
        analytical: true,
        marketing: true,
      }),
      365
    );
    // Assuming setFirstDialogOpen is defined elsewhere in your component
    setFirstDialogOpen(false); // Close the dialog
  };

  const handleCustomSettings = () => {
    // Assuming setSecondDialogOpen is defined elsewhere in lyour component
    setSecondDialogOpen(true); // Open the second dialog for detailed settings
  };

  // This function is called when the user toggles any of the switches
  const handlePreferenceChange = (
    preference: keyof UserPreferencesType,
    isEnabled: boolean
  ) => {
    setUserPreferences((prevPreferences) => ({
      ...prevPreferences,
      [preference]: isEnabled,
    }));
  };

  // This function is called when the user saves their settings
  const handleSaveSettings = () => {
    // Save the detailed settings as a cookie using js-cookie
    setCookie("cookies-preference", JSON.stringify(userPreferences), 365); // Set cookie for 1 year
    setSecondDialogOpen(false); // Close the dialog
  };

  useEffect(() => {
    // Only send GA cookies if user gave consent to analytical cookies
    if (userPreferences.analytical) {
      window.init_gtm();
      ReactGA.initialize("G-KTPLQRYC0X", {
        gaOptions: { cookieFlags: "secure;samesite=none" },
      });
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "Nexprim DTF Analytical",
      });
    }

    if (userPreferences.analytical) {
      window.init_ga();
      window.init_hotjar();
    }
  }, [location, userPreferences.analytical, userPreferences.marketing]);

  useEffect(() => {
    const header = document.getElementById("sticky-header");

    if (header) {
      if (isSelectOpen) {
        header.style.position = "relative";
      } else {
        header.style.position = "sticky";
      }
    }
  }, [isSelectOpen]);

  useEffect(() => {
    if (location.pathname === "/") {
      document.body.style.overflow = "auto";
      const elementId = location.hash.slice(1); // remove the '#' from the hash
      const element = document.getElementById(elementId);
      if (element) {
        const offset = window.innerWidth <= 1023 ? 0 : -88; // no offset for mobile devices
        window.scrollTo({
          top:
            element.getBoundingClientRect().top + window.pageYOffset + offset,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  const acceptAllButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isFirstDialogOpen) {
      // Introduce a slight delay to ensure the dialog is fully interactive
      const timer = setTimeout(() => {
        if (acceptAllButtonRef.current) {
          acceptAllButtonRef.current.focus();
        }
      }, 10); // Increased delay

      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [isFirstDialogOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        // Optionally, you can also call setFirstDialogOpen(false) here if you want to manually control the closing of the dialog
      }
    };

    if (isFirstDialogOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFirstDialogOpen]);

  return (
    <TranslationProvider translations={translations}>
      <div className="font-outfit">
        <AlertDialog open={isFirstDialogOpen} onOpenChange={setFirstDialogOpen}>
          <AlertDialogContent className="font-outfit">
            <AlertDialogHeader>
              <AlertDialogTitle>
                {translations.app.cookies.title}
              </AlertDialogTitle>
              <AlertDialogDescription>
                {translations.app.cookies.description}
              </AlertDialogDescription>
              <img
                className="relative w-1/4 my-10 md:my-6 sm:my-4 sm:w-1/3"
                alt=""
                src={cookie}
              />
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={handleCustomSettings}>
                {translations.app.cookies.cancel}
              </AlertDialogCancel>
              <AlertDialogAction
                ref={acceptAllButtonRef}
                onClick={handleAcceptAll}
              >
                {translations.app.cookies.accept}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <AlertDialog
          open={isSecondDialogOpen}
          onOpenChange={setSecondDialogOpen}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle className="text-left">
                {translations.app.cookies.settings}
              </AlertDialogTitle>
              <AlertDialogDescription className="text-left">
                {translations.app.cookies.settingsDescription}{" "}
                <a
                  href="http://www.odznaky.nexprim.sk/cookies"
                  className="text-gradient relative leading-[150%] font-bold"
                >
                  {translations.app.cookies.policy}
                </a>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <div className="flex flex-col gap-4 py-4 px-10 md:px-6 sm:px-4 text-white text-lg sm:text-md font-semibold">
              <div className="flex flex-col w-full">
                <div className="flex w-full justify-between">
                  <span>{translations.app.cookies.necessary}</span>
                  <Switch defaultChecked disabled />
                </div>
                <div className="flex w-full justify-between text-slate-400 text-base sm:text-xs font-normal">
                  {translations.app.cookies.necessaryDescription}
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex w-full justify-between">
                  <span>{translations.app.cookies.preferences}</span>
                  <Switch
                    checked={userPreferences.preferences}
                    onCheckedChange={(checked) => {
                      handlePreferenceChange("preferences", checked);
                    }}
                  />
                </div>
                <div className="flex w-full justify-between text-slate-400 text-base sm:text-xs font-normal">
                  {translations.app.cookies.preferencesDescription}
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex w-full justify-between">
                  <span>{translations.app.cookies.analytical}</span>
                  <Switch
                    checked={userPreferences.analytical}
                    onCheckedChange={(checked) => {
                      handlePreferenceChange("analytical", checked);
                    }}
                  />
                </div>
                <div className="flex w-full justify-between text-slate-400 text-base sm:text-xs font-normal">
                  {translations.app.cookies.analyticalDescription}
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex w-full justify-between">
                  <span>{translations.app.cookies.marketing}</span>
                  <Switch
                    checked={userPreferences.marketing}
                    onCheckedChange={(checked) => {
                      handlePreferenceChange("marketing", checked);
                    }}
                  />
                </div>
                <div className="flex w-full justify-between text-slate-400 text-base sm:text-xs font-normal">
                  {translations.app.cookies.marketingDescription}
                </div>
              </div>
            </div>
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={() => {
                  setSecondDialogOpen(false);
                  setUserPreferences({
                    necessary: true,
                    preferences: false,
                    analytical: false,
                    marketing: false,
                  });
                  setCookie(
                    "cookies-preference",
                    JSON.stringify({
                      necessary: true,
                      preferences: false,
                      analytical: false,
                      marketing: false,
                    }),
                    365
                  );
                }}
              >
                {translations.app.cookies.onlyNecessary}
              </AlertDialogCancel>
              <AlertDialogAction onClick={handleSaveSettings}>
                {translations.app.cookies.save}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        {/* <LandingPage /> */}
        <Header />
        <div className="App" id="App">
          <Routes>
            <Route
              path="/order"
              element={
                <>
                  {" "}
                  <Order />{" "}
                </>
              }
            />
            <Route
              path="/terms-of-service"
              element={
                <>
                  {" "}
                  <TermsOfService />{" "}
                </>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookies" element={<CookiesPolicy />} />
            <Route path="/order-confirmation" element={<OrderConfirmation />} />
            <Route path="/payment-policy" element={<PaymentPolicy />} />
            <Route path="/delivery-policy" element={<DeliveryPolicy />} />
            <Route
              path="/graphic-preparation-site"
              element={<GraphicPreparationSite />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Features />
                  <Element name="pricing">
                    <Pricing />
                  </Element>
                  <Element name="services">
                    <Services />
                  </Element>
                  <Element name="gallery">
                    <Gallery />
                  </Element>
                  <Element name="graphic_preparation">
                    <GraphicPreparation />
                  </Element>
                  <Element name="testimonials">
                    <Testimonials />
                  </Element>
                  <Element name="metrics">
                    <Metrics />
                  </Element>
                  <Element name="faq">
                    <FAQ />
                  </Element>
                </>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </TranslationProvider>
  );
}

function App() {
  return (
    <Router>
      <InnerApp />
    </Router>
  );
}

export default App;
